<template>
  <v-container>
    <v-row>
      <h1>{{ $t("Ordres") }}</h1>
    </v-row>
    <v-row v-for="(item, index) in ordres" :key="index" class="mb-1">
      <v-col>
        <v-card>
          <v-card-title class="blue">
            <v-container>
              <v-row>
                <v-expansion-panels tile flat class="mt-n2">
                  <v-expansion-panel style="background: #2196f3">
                    <v-expansion-panel-header>
                      <div class="text-h6 ml-n6">
                        {{ $t("Ordre") }} {{ item.ordre }} /
                        {{ item.subordre }}
                        <span v-if="item.mostrarNoms == 'C' || item.mostrarNoms == 'T'
                          ">
                          <br />
                          {{ item.nomClient }}
                        </span>
                        <span v-if="item.mostrarNoms == 'V' || item.mostrarNoms == 'T'
                          ">
                          <br />
                          {{ item.nomVaixell }}
                        </span>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="text-subtitle-1">
                        <span class="font-weight-bold">{{
                          $t("Data entrega:")
                        }}</span>
                        {{ item.dataEntrega }}
                      </div>
                      <div class="text-subtitle-1">
                        <span class="font-weight-bold">{{
                          $t("Matrícula:")
                        }}</span>
                        {{ item.matricula }}
                      </div>
                      <div class="text-subtitle-1">
                        <span class="font-weight-bold">{{
                          $t("Eslora:")
                        }}</span>
                        {{ item.eslora }}
                      </div>
                      <div class="text-subtitle-1">
                        <span class="font-weight-bold">{{
                          $t("Situació:")
                        }}</span>
                        {{ item.situacio }}
                      </div>
                      <div class="text-subtitle-1">
                        <span class="font-weight-bold">{{ $t("Claus:") }}</span>
                        {{ item.claus }}
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </v-container>
          </v-card-title>
          <v-card-text>
            <h3 class="mt-2">{{ $t("Feines_a_fer") }}</h3>
            <v-form>
              <v-textarea v-if="item.observacionsOrdre != null &&
                item.observacionsOrdre.trim() != ''
                " auto-grow rows="1" readonly :value="item.observacionsOrdre" hide-details="auto"></v-textarea>
              <v-expansion-panels tile flat>
                <v-expansion-panel>
                  <v-expansion-panel-header style="padding: 0">
                    <h4 v-if="item.descripcio != null && item.descripcio.trim() != ''
                      ">
                      {{ item.descripcio }}
                    </h4>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="padding: 0 0 0">
                    <v-textarea v-if="item.observacionsSubordre != null &&
                      item.observacionsSubordre.trim() != ''
                      " auto-grow rows="1" readonly :value="item.observacionsSubordre"
                      hide-details="auto"></v-textarea>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="iniciarOrdre(item)" color="green" class="mt-2">
              {{ $t("Iniciar") }}
              <v-icon right dark>mdi-arrow-right-thick</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-btn class="mt-4" fab to="/">
        <v-icon dark>mdi-arrow-left-thick</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import api from "../api.js";

export default {
  data: () => ({
    ordres: [],
    ultMilis: new Date().getTime(),
  }),
  methods: {
    iniciarOrdre1(ordre, lat, lng) {
      axios
        .post(api.URL() + "/api/v1/entradaOrdre", {
          ordre: ordre.ordre,
          subordre: ordre.subordre,
          idOperari: Number(localStorage.getItem("systimes.idOperari" + api.getEmp())),
          nomOperari: localStorage.getItem("systimes.nomOperari" + api.getEmp()),
          lat: lat,
          lng: lng,
        }, api.header())
        .then(() => {
          var time = localStorage.getItem("systimes.time" + api.getEmp());
          var estat;
          var nom;

          if (ordre.mostrarNoms == "V") {
            nom = ordre.nomVaixell;
          } else {
            nom = ordre.nomClient;
          }

          if (nom != "") {
            estat = this.$t('IniciOrdre') + nom + " " + time;
          } else {
            estat =
              this.$t('IniciOrdre') + ordre.ordre + "/" + ordre.subordre + " " + time;
          }
          localStorage.setItem("systimes.estat" + api.getEmp(), estat);
          localStorage.setItem("systimes.ordre" + api.getEmp(), JSON.stringify(ordre));
          this.$router.push("/");
        })
        .catch(() => { });
    },
    iniciarOrdre(ordre) {
      var milis = new Date().getTime();
      console.log(milis, this.ultMilis);
      if (milis - this.ultMilis > 1000) {
        /*
        this.$getLocation()
          .then((coordinates) => {
            this.iniciarOrdre1(ordre, coordinates.lat, coordinates.lng);
          })
          .catch(() => {
            this.iniciarOrdre1(ordre, 0.0, 0.0);
          });
        */
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              this.iniciarOrdre1(
                ordre,
                position.coords.latitude,
                position.coords.longitude
              );
              console.log(position);
            },
            (error) => {
              console.log(error);
              this.iniciarOrdre1(ordre, 0.0, 0.0);
            }
          );
        } else {
          this.iniciarOrdre1(ordre, 0.0, 0.0);
        }
      }
      this.ultMilis = new Date().getTime();
    },
  },
  created() {
    axios
      .post(api.URL() + "/api/v1/ordres", {
        id: Number(localStorage.getItem("systimes.idOperari" + api.getEmp())),
      })
      .then((response) => {
        this.ordres = response.data;
      }, api.header());
  },
};
</script>
