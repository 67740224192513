
<template>
  <div>
    <v-container>
       <v-row>
        <v-btn class="mb-2" fab to="/">
          <v-icon dark>mdi-arrow-left-thick</v-icon>
        </v-btn>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title class="blue">
              <v-container>
                <v-row>
                  <v-expansion-panels tile flat class="mt-n2">
                    <v-expansion-panel style="background: #2196f3">
                      <v-expansion-panel-header>
                        <div class="text-h6 ml-n6">
                          {{ $t("Ordre") }} {{ ordre.albara }}
                          <br />
                          {{ ordre.nom }} ({{ ordre.client }})
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div class="text-subtitle-1">
                          <span class="font-weight-bold">{{
                            $t("Adreca")
                          }}</span>
                          {{ ordre.domicili }}
                        </div>
                        <div class="text-subtitle-1">
                          <span class="font-weight-bold">{{
                            $t("Poblacio")
                          }}</span>
                          {{ ordre.poblacio }}
                        </div>
                        <div class="text-subtitle-1">
                          <span class="font-weight-bold">{{
                            $t("Telefon")
                          }}</span>
                          {{ ordre.telefon }}
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text>
              <h3 class="mt-2">{{ $t("Feines_a_fer") }}</h3>
              <v-form>
                <v-textarea
                  v-if="
                    ordre.observacionsOrdre != null &&
                    ordre.observacionsOrdre.trim() != ''
                  "
                  auto-grow
                  rows="1"
                  readonly
                  :value="ordre.observacionsOrdre"
                  hide-details="auto"
                ></v-textarea>
                <v-text-field
                  v-if="
                    ordre.descripcio != null && ordre.descripcio.trim() != ''
                  "
                  readonly
                  :value="ordre.descripcio"
                  hide-details="auto"
                ></v-text-field>
                <v-textarea
                  v-if="
                    ordre.observacionsSubordre != null &&
                    ordre.observacionsSubordre.trim() != ''
                  "
                  auto-grow
                  rows="1"
                  readonly
                  :value="ordre.observacionsSubordre"
                  hide-details="auto"
                ></v-textarea>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <!-- <v-row justify="center" class="mt-5 mb-5">
        <v-btn @click="ferFoto()">{{ $t("IncorporarFotosOrdre") }}</v-btn>
      </v-row> -->
      <v-row justify="center" class="mt-5 mb-5">
        <v-btn @click="firmar()">{{ $t("FirmarOrdre") }}</v-btn>
      </v-row>
      <v-row>
        <h2 class="ml-3 mt-4 mb-2">{{ $t("Petit_material") }}</h2>
      </v-row>
      <v-row v-for="(item, index) in articles" :key="index" class="mb-1">
        <v-col>
          <v-card>
            <v-card-title class="grey lighten-3">
              <v-container fluid>
                <v-row align="center">
                  <v-checkbox v-model="checkbox[index]"></v-checkbox>
                  <span
                    class="text-body-1 font-weight-bold"
                    @click="select(index)"
                    >{{ item.descripcio }}</span
                  >
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text v-if="checkbox[index]">
              <v-form :ref="'form_' + index">
                <v-text-field
                  :label="$t('Quantitat')"
                  v-model="quantitat[index]"
                  type="number"
                  :rules="[(v) => !!v || $t('Requerit')]"
                ></v-text-field
              ></v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="ml-0">
        <v-btn class="" to="/">
          {{ $t("Cancelar") }}
        </v-btn>
        <v-btn class="ml-2" color="green" @click="send()">
          {{ $t("Acceptar") }}
        </v-btn>
      </v-row>

      <v-row>
        <v-btn class="mt-6" fab to="/">
          <v-icon dark>mdi-arrow-left-thick</v-icon>
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>
      
<script>
import axios from "axios";
import api from "../api.js";

export default {
  data: () => ({
    articles: [],
    checkbox: [],
    quantitat: [],
    ordre: null,
  }),
  created() {
    var or = localStorage.getItem("systimes.ordre" + api.getEmp());
    if (or != null && or != "") this.ordre = JSON.parse(or);
    this.requerit = this.$t("Requerit");
    axios.get(api.URL() + "/api/v1/petitMaterialG", api.header()).then((response) => {
      this.articles = response.data;
      this.load();
    });
  },
  methods: {
    select(index) {
      this.checkbox[index] = !this.checkbox[index];
      this.$forceUpdate();
    },
    load() {
      var material = localStorage.getItem("systimes.material" + api.getEmp());
      if (material != null && material != "") {
        var mat = JSON.parse(material);
        for (var i = 0; i < mat.length; i++) {
          var idx = mat[i].idx;
          this.checkbox[idx] = true;
          this.quantitat[idx] = mat[i].quantitat.toString();
        }
      }
    },
    send() {
      var material = [];
      var items = 0;

      this.checkbox.forEach((item, idx) => {
        if (item == true) {
          ++items;
          if (this.$refs["form_" + idx][0].validate()) {
            var obj = {};

            obj.idx = idx;
            obj.article = this.articles[idx].id;
            obj.descripcio = this.articles[idx].descripcio;
            obj.quantitat = parseFloat(this.quantitat[idx]);

            material.push(obj);
          }
        }
      });

      if (material.length > 0 && material.length == items) {
        localStorage.setItem("systimes.material" + api.getEmp(), JSON.stringify(material));
        this.$router.push(this.$store.state.back);
      }
    },
    ferFoto() {
      this.$router.push("/foto");
    },
    firmar() {
      this.$router.push("/firmar");
    },
  },
};
</script>
      
