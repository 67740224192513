import Vue from "vue";
import VueRouter from "vue-router";
import Home from '../views/Home.vue'
import Settings from '../views/Settings.vue'
import Token from '../views/Token.vue'
import Ordres from '../views/Ordres.vue'
import OrdresG from '../views/OrdresG.vue'
import OrdresPendents from '../views/OrdresPendents.vue'
import OrdresPendentsG from '../views/OrdresPendentsG.vue'
import Material from '../views/Material.vue'
import MaterialG from '../views/MaterialG.vue'
import Foto from '../views/Foto.vue'
import Firmar from '../views/Firmar.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
  },
  {
    path: "/token",
    name: "token",
    component: Token,
  },
  {
    path: "/ordres",
    name: "ordres",
    component: Ordres,
  },
  {
    path: "/ordresG",
    name: "ordresG",
    component: OrdresG,
  },
  {
    path: "/ordresPendents",
    name: "ordresPendents",
    component: OrdresPendents,
  },
  {
    path: "/ordresPendentsG",
    name: "ordresPendentsG",
    component: OrdresPendentsG,
  },
  {
    path: "/material",
    name: "material",
    component: Material,
  },
  {
    path: "/materialG",
    name: "materialG",
    component: MaterialG,
  },
  {
    path: "/foto",
    name: "foto",
    component: Foto,
  },
  {
    path: "/firmar",
    name: "firmar",
    component: Firmar,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
