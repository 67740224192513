
<template>
  <div>
    <v-container>
      <v-row>
        <v-btn class="mb-2" fab to="/">
          <v-icon dark>mdi-arrow-left-thick</v-icon>
        </v-btn>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title class="blue">
              <v-container>
                <v-row>
                  <v-expansion-panels tile flat class="mt-n2">
                    <v-expansion-panel style="background: #2196f3">
                      <v-expansion-panel-header>
                        <div class="text-h6 ml-n6">
                          {{ $t("Ordre") }} {{ ordre.ordre }} /
                          {{ ordre.subordre }}
                          <span
                            v-if="
                              ordre.mostrarNoms == 'C' ||
                              ordre.mostrarNoms == 'T'
                            "
                          >
                            <br />
                            {{ ordre.nomClient }}
                          </span>
                          <span
                            v-if="
                              ordre.mostrarNoms == 'V' ||
                              ordre.mostrarNoms == 'T'
                            "
                          >
                            <br />
                            {{ ordre.nomVaixell }}
                          </span>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div class="text-subtitle-1">
                          <span class="font-weight-bold">{{
                            $t("Data entrega:")
                          }}</span>
                          {{ ordre.dataEntrega }}
                        </div>
                        <div class="text-subtitle-1">
                          <span class="font-weight-bold">{{
                            $t("Matrícula:")
                          }}</span>
                          {{ ordre.matricula }}
                        </div>
                        <div class="text-subtitle-1">
                          <span class="font-weight-bold">{{
                            $t("Eslora:")
                          }}</span>
                          {{ ordre.eslora }}
                        </div>
                        <div class="text-subtitle-1">
                          <span class="font-weight-bold">{{
                            $t("Situació:")
                          }}</span>
                          {{ ordre.situacio }}
                        </div>
                        <div class="text-subtitle-1">
                          <span class="font-weight-bold">{{
                            $t("Claus:")
                          }}</span>
                          {{ ordre.claus }}
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text>
              <h3 class="mt-2">{{ $t("Feines_a_fer") }}</h3>
              <v-form>
                <v-textarea
                  v-if="
                    ordre.observacionsOrdre != null &&
                    ordre.observacionsOrdre.trim() != ''
                  "
                  auto-grow
                  rows="1"
                  readonly
                  :value="ordre.observacionsOrdre"
                  hide-details="auto"
                ></v-textarea>
                <v-text-field
                  v-if="
                    ordre.descripcio != null && ordre.descripcio.trim() != ''
                  "
                  readonly
                  :value="ordre.descripcio"
                  hide-details="auto"
                ></v-text-field>
                <v-textarea
                  v-if="
                    ordre.observacionsSubordre != null &&
                    ordre.observacionsSubordre.trim() != ''
                  "
                  auto-grow
                  rows="1"
                  readonly
                  :value="ordre.observacionsSubordre"
                  hide-details="auto"
                ></v-textarea>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <!-- <v-row justify="center" class="mt-5 mb-5">
        <v-btn @click="ferFoto()">{{ $t("IncorporarFotosOrdre") }}</v-btn>
      </v-row> -->
      <v-row justify="center" class="mt-5 mb-5">
        <v-btn @click="firmar()">{{ $t("FirmarOrdre") }}</v-btn>
      </v-row>
      <v-row>
        <h2 class="ml-3 mt-4 mb-2">{{ $t("Petit_material") }}</h2>
      </v-row>
      <v-row v-for="(item, index) in articles" :key="index" class="mb-1">
        <v-col>
          <v-card>
            <v-card-title class="grey lighten-3">
              <v-container fluid>
                <v-row align="center">
                  <v-checkbox v-model="checkbox[index]"></v-checkbox>
                  <span
                    class="text-body-1 font-weight-bold"
                    @click="select(index)"
                    >{{ item.descripcio }}</span
                  >
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text v-if="checkbox[index]">
              <v-form :ref="'form_' + index">
                <v-textarea
                  v-model="observacions[index]"
                  :label="$t('Observacions')"
                  hide-details="auto"
                ></v-textarea>
                <v-text-field
                  :label="$t('Import')"
                  v-model="preu[index]"
                  type="number"
                  :rules="[(v) => !!v || $t('Requerit')]"
                ></v-text-field
              ></v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="ml-0">
        <v-btn class="" to="/">
          {{ $t("Cancelar") }}
        </v-btn>
        <v-btn class="ml-2" color="green" @click="send()">
          {{ $t("Acceptar") }}
        </v-btn>
      </v-row>

      <v-row>
        <v-btn class="mt-6" fab to="/">
          <v-icon dark>mdi-arrow-left-thick</v-icon>
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>
      
<script>
import axios from "axios";
import api from "../api.js";

export default {
  data: () => ({
    articles: [],
    checkbox: [],
    observacions: [],
    preu: [],
    ordre: null,
  }),
  created() {
    var or = localStorage.getItem("systimes.ordre" + api.getEmp());
    if (or != null && or != "") this.ordre = JSON.parse(or);
    this.requerit = this.$t("Requerit");
    axios.get(api.URL() + "/api/v1/petitMaterial", api.header()).then((response) => {
      this.articles = response.data;
    });
  },
  methods: {
    select(index) {
      this.checkbox[index] = !this.checkbox[index];
      this.$forceUpdate();
    },
    send() {
      var material = [];
      var items = 0;

      this.checkbox.forEach((item, idx) => {
        if (item == true) {
          ++items;
          if (this.$refs["form_" + idx][0].validate()) {
            var obj = {};

            obj.operari = Number(localStorage.getItem("systimes.idOperari" + api.getEmp()));
            obj.ordre = parseInt(this.ordre.ordre);
            obj.subordre = parseInt(this.ordre.subordre);
            obj.article = this.articles[idx].id;
            obj.descripcio = this.articles[idx].descripcio;
            obj.observacions = this.observacions[idx];
            obj.import = parseFloat(this.preu[idx]);

            material.push(obj);
          }
        }
      });

      var imatges = this.$store.state.imatges;

      if (material.length > 0 && material.length == items) {
        axios.post(api.URL() + "/api/v1/entrarMaterial", material, api.header()).then(() => {
          // Enviar fotos al backend
          if (imatges.length > 0) {
            this.enviarFotos(imatges);
          }
          this.$router.push("/");
        });
      } else if (imatges.length > 0) {
        this.enviarFotos(imatges);
        this.$router.push("/");
      }
    },
    ferFoto() {
      this.$router.push("/foto");
    },
    firmar() {
      this.$router.push("/firmar");
    },
    enviarFotos(imatges) {
      // Convert images to raw byte array
      for (var i = 0; i < imatges.length; i++) {
        imatges[i] = imatges[i].split(",")[1];
      }

      var dataFotos;
      if (imatges.length) {
        dataFotos = {
          subordre: parseInt(this.ordre.subordre),
          fotos: imatges,
        };
        axios
          .post(api.URL() + "/api/v1/entrarFotos", dataFotos, api.header())
          .then(() => {
            // Esborrar les fotos del store
            this.$store.commit("setImatges", []);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>
      
