<template>
  <v-container>
    <v-row>
      <h1>{{ $t("Ordres") }}</h1>
    </v-row>
    <v-row v-for="(item, index) in ordres" :key="index" class="mb-1">
      <v-col>
        <v-card>
          <v-card-title class="blue">
            <v-container>
              <v-row>
                <v-expansion-panels tile flat class="mt-n2">
                  <v-expansion-panel style="background: #2196f3">
                    <v-expansion-panel-header>
                      <div class="text-h6 ml-n6">
                        {{ $t("Ordre") }} {{ item.albara }}
                        <br />
                        {{ item.nom }} ({{ item.client }})
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="text-subtitle-1">
                        <span class="font-weight-bold">{{
                          $t("Adreca")
                        }}</span>
                        {{ item.domicili }}
                      </div>
                      <div class="text-subtitle-1">
                        <span class="font-weight-bold">{{
                          $t("Poblacio")
                        }}</span>
                        {{ item.poblacio }}
                      </div>
                      <div class="text-subtitle-1">
                        <span class="font-weight-bold">{{
                          $t("Telefon")
                        }}</span>
                        {{ item.telefon }}
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </v-container>
          </v-card-title>
          <v-card-text>
            <h3 class="mt-2">{{ $t("Feines_a_fer") }}</h3>
            <v-form>
              <v-textarea
                v-if="
                  item.observacionsOrdre != null &&
                  item.observacionsOrdre.trim() != ''
                "
                auto-grow
                rows="1"
                readonly
                :value="item.observacionsOrdre"
                hide-details="auto"
              ></v-textarea>
              <v-expansion-panels tile flat>
                <v-expansion-panel>
                  <v-expansion-panel-header style="padding: 0">
                    <h4
                      v-if="
                        item.descripcio != null && item.descripcio.trim() != ''
                      "
                    >
                      {{ item.descripcio }}
                    </h4>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="padding: 0 0 0">
                    <v-textarea
                      v-if="
                        item.observacionsSubordre != null &&
                        item.observacionsSubordre.trim() != ''
                      "
                      auto-grow
                      rows="1"
                      readonly
                      :value="item.observacionsSubordre"
                      hide-details="auto"
                    ></v-textarea>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="iniciarOrdre(item)" color="green" class="mt-2">
              {{ $t("Iniciar") }}
              <v-icon right dark>mdi-arrow-right-thick</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-btn class="mt-4" fab to="/">
        <v-icon dark>mdi-arrow-left-thick</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import api from "../api.js";

export default {
  data: () => ({
    ordres: [],
    ultMilis: new Date().getTime(),
    ordre: {},
  }),
  methods: {
    /*
    iniciarOrdre1(ordre, lat, lng) {
      axios
        .post(api.URL() + "/api/v1/entradaOrdre", {
          ordre: ordre.ordre,
          subordre: ordre.subordre,
          idOperari: Number(localStorage.getItem("systimes.idOperari" + api.getEmp())),
          nomOperari: localStorage.getItem("systimes.nomOperari" + api.getEmp()),
          lat: lat,
          lng: lng,
        }, api.header())
        .then(() => {
          var time = localStorage.getItem("systimes.time" + api.getEmp());
          var estat;
          var nom;

          if (ordre.mostrarNoms == "V") {
            nom = ordre.nomVaixell;
          } else {
            nom = ordre.nomClient;
          }

          if (nom != "") {
            estat = "Inici ordre " + nom + " " + time;
          } else {
            estat =
              "Inici ordre " + ordre.ordre + "/" + ordre.subordre + " " + time;
          }
          localStorage.setItem("systimes.estat" + api.getEmp(), estat);
          localStorage.setItem("systimes.ordre" + api.getEmp(), JSON.stringify(ordre));
          this.$router.push("/");
        })
        .catch(() => {});
    },
    iniciarOrdre(ordre) {
      var milis = new Date().getTime();
      console.log(milis, this.ultMilis);
      if (milis - this.ultMilis > 1000) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              this.iniciarOrdre1(
                ordre,
                position.coords.latitude,
                position.coords.longitude
              );
              console.log(position);
            },
            (error) => {
              console.log(error);
              this.iniciarOrdre1(ordre, 0.0, 0.0);
            }
          );
        } else {
          this.iniciarOrdre1(ordre, 0.0, 0.0);
        }
      }
      this.ultMilis = new Date().getTime();
    },
    */

    eventEntrada(lat, lng, ordre) {      
      var estat;
      console.log("Entrada...");
      this.error = false;
      axios
        .post(api.URL() + "/api/v1/event", {
          id: Number(localStorage.getItem("systimes.idOperari" + api.getEmp())),
          tipus: "I",
          tipusFitxada:  parseInt(localStorage.getItem("systimes.tipusFixada" + api.getEmp())),
          lat: lat,
          lng: lng,
          ordre: ordre.albara.toString(),
        }, api.header())
        .then((response) => {
          /*
          if (this.tipusFitxada == 0) {
            this.estat = "Entrada " + this.time;
          } else {
            console.log(this.tipusFitxada);
            this.estat = this.tipusFitxada.nom + " " + this.time;
            this.tipusFitxada = 0;
            this.desactivarFi = false;
          }
          */
          var d = new Date();
          var time =
            d.getHours().toString().padStart(2, "0") +
            ":" +
            d.getMinutes().toString().padStart(2, "0");
          this.seconds = d.getSeconds().toString().padStart(2, "0");

          estat = this.$t('IniciOrdre') + response.data + " " + time;

          localStorage.setItem("systimes.estat" + api.getEmp(), estat);
          localStorage.setItem("systimes.ordre" + api.getEmp(), JSON.stringify(ordre));
          // Guardar data en format AAAA-MM-DD
          var dataString =
            d.getFullYear() +
            "-" +
            (d.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            d.getDate().toString().padStart(2, "0");
          localStorage.setItem("systimes.dataIniciOrdre" + api.getEmp(), dataString);
          // Guardar hora en format HH:MM:SS
          var horaString =
            d.getHours().toString().padStart(2, "0") +
            ":" +
            d.getMinutes().toString().padStart(2, "0") +
            ":" +
            d.getSeconds().toString().padStart(2, "0");
          localStorage.setItem("systimes.horaIniciOrdre" + api.getEmp(), horaString);

          this.$router.push("/");
        })
        .catch((err) => {
          console.log(err);
          this.error = true;
          this.tipusFitxada = 0;
          this.desactivarFi = false;
          if (err.response.status == 401) {
            if (localStorage.getItem("systimes.idioma" + api.getEmp()) == "Català") {
              this.estat = "Usuari no autoritzat";
            } else {
              this.estat = "Usuario no autorizado";
            }
          } else if (err.response.status == 409) {
            if (localStorage.getItem("systimes.idioma" + api.getEmp()) == "Català") {
              this.estat =
                "Presència ignorada per marge de temps amb una altra presència a les " +
                err.response.data;
            } else {
              this.estat =
                "Presencia ignorada por margen de tiempo con otra presencia a las" +
                err.response.data;
            }
          } else {
            if (localStorage.getItem("systimes.idioma" + api.getEmp()) == "Català") {
              this.estat = "Error connectant amb el servidor";
            } else {
              this.estat = "Error conectando con el servidor";
            }
          }

          this.$router.push("/");
        });
    },

    iniciarOrdre(ordre) {
      var milis = new Date().getTime();
      console.log(milis, this.ultMilis);
      if (milis - this.ultMilis > 1000) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              this.eventEntrada(
                position.coords.latitude,
                position.coords.longitude,
                ordre,
              );
              console.log(position);
            },
            (error) => {
              console.log(error);
              this.eventEntrada(0, 0, ordre);
            }
          );
        } else {
          this.eventEntrada(0, 0, ordre);
        }
      }
      this.ultMilis = new Date().getTime();
    },
  },

  created() {
    axios
      .post(api.URL() + "/api/v1/ordres", {
        id: Number(localStorage.getItem("systimes.idOperari" + api.getEmp())),
      }, api.header())
      .then((response) => {
        this.ordres = response.data;
      });
  },
};
</script>
