<template>
  <v-container v-if="auth">
    <v-row class="mt-4">
      <v-select label="Idioma" v-model="idioma" :items="idiomes"></v-select>
    </v-row>
    <v-row>
      <v-select :label="$t('Operari')" v-model="idOperari" :items="operaris" item-value="id" item-text="nom"
        :disabled="desactivat"></v-select>
    </v-row>
    <v-row>
      <v-text-field v-model="password" :label="$t('Contrasenya')" type="password"></v-text-field>
    </v-row>
    <v-row justify="end" class="mt-4">
      <v-btn color="grey" to="/">{{ $t("Cancel·lar") }}</v-btn>
      <v-btn class="ml-3" color="success" @click="guardar">{{
        $t("Acceptar")
      }}</v-btn>
    </v-row>
    <v-alert :value="error" type="error">{{
      $t("Contrasenya_incorrecta")
    }}</v-alert>
  </v-container>
</template>

<script>
import axios from "axios";
import api from "../api.js";

export default {
  data: () => ({
    idOperari: Number(localStorage.getItem("systimes.idOperari" + api.getEmp())),
    operari: {},
    operaris: [],
    password: "",
    error: false,
    desactivat: true,
    idiomes: ["Català", "Castellano"],
    idioma: localStorage.getItem("systimes.idioma" + api.getEmp()),
    auth: false,
  }),
  methods: {
    getOperari() {
      for (var o of this.operaris) {
        if (o.id == this.idOperari) {
          this.operari = o;
          break;
        }
      }
    },
    setIdioma() {
      if (localStorage.getItem("systimes.idioma" + api.getEmp()) == "Català") {
        this.$i18n.locale = "ca";
      } else {
        this.$i18n.locale = "es";
      }
    },
    guardar() {
      this.getOperari();
      if (this.operari.clau == this.password) {
        localStorage.setItem("systimes.idOperari" + api.getEmp(), this.operari.id.toString());
        localStorage.setItem("systimes.nomOperari" + api.getEmp(), this.operari.nom);
        this.$router.push("/");
      } else {
        this.error = true;
      }
    },
  },
  updated() {
    this.auth = this.$store.state.auth;
  },
  mounted() {
    this.auth = this.$store.state.auth;
  },
  created() {
    this.auth = this.$store.state.auth;

    axios.get(api.URL() + "/api/v1/permis", api.header()).then((response) => {
      if (response.data == "S") {
        this.desactivat = false;
      } else {
        this.desactivat = true;
      }
    });
    axios.get(api.URL() + "/api/v1/operaris", api.header()).then((response) => {
      this.operaris = response.data;
      this.idOperari = Number(localStorage.getItem("systimes.idOperari" + api.getEmp()));
    });
    this.setIdioma();
  },
  watch: {
    idioma(val) {
      localStorage.setItem("systimes.idioma" + api.getEmp(), val);
      this.setIdioma();
    },
  },
};
</script>
