<template>
  <v-container>
    <v-row>
      <h1>{{ titol }}</h1>
    </v-row>
    <v-row v-for="(item, index) in ordres" :key="index" class="mb-1">
      <v-col>
        <v-card>
          <v-card-title class="red">
            <v-container>
              <v-row>
                <v-expansion-panels tile flat class="mt-n2">
                  <v-expansion-panel class="red">
                    <v-expansion-panel-header>
                      <div class="text-h6 ml-n6">
                        {{ $t("Ordre") }} {{ item.albara }}
                        <br />
                        {{ item.nom }} ({{ item.client }})
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="text-subtitle-1">
                        <span class="font-weight-bold">{{
                          $t("Adreça:")
                        }}</span>
                        {{ item.domicili }}
                      </div>
                      <div class="text-subtitle-1">
                        <span class="font-weight-bold">{{
                          $t("Població:")
                        }}</span>
                        {{ item.poblacio }}
                      </div>
                      <div class="text-subtitle-1">
                        <span class="font-weight-bold">{{
                          $t("Telefon:")
                        }}</span>
                        {{ item.telefon }}
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </v-container>
          </v-card-title>
          <v-card-text>
            <h3 class="mt-2">{{ $t("Feines_a_fer") }}</h3>
            <v-form>
              <v-textarea v-if="
                item.observacionsOrdre != null &&
                item.observacionsOrdre.trim() != ''
              " auto-grow rows="1" readonly :value="item.observacionsOrdre" hide-details="auto"></v-textarea>
              <v-text-field v-if="item.descripcio != null && item.descripcio.trim() != ''" readonly
                :value="item.descripcio" hide-details="auto"></v-text-field>
              <v-textarea v-if="
                item.observacionsSubordre != null &&
                item.observacionsSubordre.trim() != ''
              " auto-grow rows="1" readonly :value="item.observacionsSubordre" hide-details="auto"></v-textarea>
            </v-form>
            <div v-if="principal == 'S' || permisImputar == 'S'">
              <h3 class="mt-6">{{ $t("FeinesFetes") }}</h3>
              <v-form>
                <v-textarea v-model="feinesFetes[index]" hide-details="auto"></v-textarea>
              </v-form>

              <div v-if="principal == 'S' || permisImputarMaterial == 'S'">
                <!-- llista del material -->
                <h3 class="mt-6">{{ $t("Material") }}</h3>

                <v-data-table :headers="[
                  { text: $t('Descripcio'), value: 'descripcio' },
                  {
                    text: $t('Quantitat'),
                    value: 'quantitat',
                    align: 'right',
                  },
                ]" :items="material" :items-per-page="10" :pagination.sync="pagination" :loading="loading"
                  :loading-text="$t('Carregant')" :no-data-text="$t('NoHiHaDades')"
                  :no-results-text="$t('NoHiHaResultats')" :footer-props="{
                    itemsPerPageOptions: [10, 20],
                    itemsPerPageText: '',
                  }">
                </v-data-table>
                <!-- fi llista del material -->

                <v-btn class="mt-5" @click="entrarMaterial()" rounded color="blue" style="color: white">{{
                  $t("OpcionsOrdre") }}</v-btn>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-container>
              <v-row>
                <v-col>
                  <v-btn v-if="principal == 'S'" :disabled="pendentVisible || deshabilitarFinalitzar"
                    @click="finalitzarOrdre(item, index, 'S', 'F')" color="green" class="mt-2">
                    {{ $t("FinalitzarOrdre") }}
                  </v-btn>
                  <v-btn v-else :disabled="pendentVisible || deshabilitarFinalitzar"
                    @click="finalitzarOrdre(item, index, 'N', '')" color="green" class="mt-2">
                    {{ $t("FinalitzarFitxada") }}
                  </v-btn>
                  <v-btn v-if="principal == 'S'" @click="pendentVisible = !pendentVisible" color="blue" class="mt-2 ml-2">
                    {{ $t("DeixarPendent") }}
                    <v-icon v-if="pendentVisible" right dark>mdi-chevron-up</v-icon>
                    <v-icon v-else right dark>mdi-chevron-down</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-card flat v-if="pendentVisible">
                    <v-card-text>
                      <!-- llista de motius v-list -->
                      <h3 class="mt-6">{{ $t("Motiu") }}</h3>
                      <v-list>
                        <v-list-item-group v-model="motiu" color="primary">
                          <v-list-item v-for="(item, i) in motius" :key="i" :value="item.id"
                            @click="addObservacionsPendent(index, item.text)">
                            <v-list-item-content>
                              <v-list-item-title>{{
                                item.descripcio
                              }}</v-list-item-title>
                              <v-list-item-subtitle>{{
                                item.text
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>

                      <v-textarea v-model="observacionsPendent[index]" label="Observacions"
                        hide-details="auto"></v-textarea>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn color="blue" class="mr-2" @click="deixarPendent(item, index)">
                        {{ $t("DeixarPendent") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                  <v-alert v-model="alertaMotiu[index]" color="red">
                    {{ $t("Cal_triar_un_motiu") }}</v-alert>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-btn class="mt-4" fab @click="enrrera()">
        <v-icon dark>mdi-arrow-left-thick</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import api from "../api.js";

export default {
  data: () => ({
    deshabilitarFinalitzar: false,
    pendentVisible: false,
    ordres: [],
    feinesFetes: [],
    ultMilis: new Date().getTime(),
    alertaMotiu: [],
    motiu: [],
    observacionsPendent: [],
    titol: "",
    material: [],
    motius: [],
    principal: "N",
    permisImputar: "N",
    permisImputarMaterial: "N",
  }),
  methods: {
    addObservacionsPendent(index, text) {
      this.observacionsPendent[index] = text;
    },
    loadMaterial() {
      this.material = localStorage.getItem("systimes.material" + api.getEmp());
      if (this.material == null) {
        this.material = [];
      } else {
        this.material = JSON.parse(this.material);
      }
    },
    entrarMaterial() {
      this.$store.commit("setBack", "/OrdresPendentsG");
      this.$router.push("MaterialG");
    },
    enrrera() {
      localStorage.setItem("systimes.inici" + api.getEmp(), "0");
      this.$router.push("/");
    },
    // Data actual AAAA-MM-DD
    dataActual() {
      var data = new Date();
      var dia = data.getDate();
      var mes = data.getMonth() + 1;
      var any = data.getFullYear();
      if (dia < 10) {
        dia = "0" + dia;
      }
      if (mes < 10) {
        mes = "0" + mes;
      }
      return any + "-" + mes + "-" + dia;
    },
    // Hora actual HH:MM:SS
    horaActual() {
      var data = new Date();
      var hora = data.getHours();
      var minuts = data.getMinutes();
      var segons = data.getSeconds();
      if (hora < 10) {
        hora = "0" + hora;
      }
      if (minuts < 10) {
        minuts = "0" + minuts;
      }
      if (segons < 10) {
        segons = "0" + segons;
      }
      return hora + ":" + minuts + ":" + segons;
    },
    intercanvi(ordre, index, finalitzada, estat) {
      var dataFitxada = {
        accio: "FITXADA",
        canal: ordre.canal,
        diari: ordre.diari,
        any: ordre.any,
        ordre: ordre.albara,
        operari: Number(localStorage.getItem("systimes.idOperari" + api.getEmp())),
        dataInici: localStorage.getItem("systimes.dataIniciOrdre" + api.getEmp()),
        horaInici: localStorage.getItem("systimes.horaIniciOrdre" + api.getEmp()),
        dataFi: this.dataActual(),
        horaFi: this.horaActual(),
        feinaFeta: this.feinesFetes[index],
        signaturaB64: localStorage.getItem("systimes.signatura" + api.getEmp()),
      };
      var dataFinalitzacio = {
        accio: "FINALITZACIO",
        canal: ordre.canal,
        diari: ordre.diari,
        any: ordre.any,
        ordre: ordre.albara,
        operari: Number(localStorage.getItem("systimes.idOperari" + api.getEmp())),
        motiuNoFinalitzacio: this.observacionsPendent[index],
        ordreFinalitzada: estat == "P" ? "N" : "S",
      };

      console.log("--------------------");
      console.log(this.observacionsPendent[index]);
      console.log("--------------------");

      var dataMaterial = {
        canal: ordre.canal,
        diari: ordre.diari,
        any: ordre.any,
        ordre: ordre.albara,
        operari: Number(localStorage.getItem("systimes.idOperari" + api.getEmp())),
        material: JSON.parse(localStorage.getItem("systimes.material" + api.getEmp())),
      };
      var imatges = this.$store.state.imatges;
      // Convert images to raw byte array
      for (var i = 0; i < imatges.length; i++) {
        imatges[i] = imatges[i].split(",")[1];
      }
      var dataFotos;
      if (imatges.length) {
        dataFotos = {
          canal: ordre.canal,
          diari: ordre.diari,
          any: ordre.any,
          ordre: ordre.albara,
          operari: Number(localStorage.getItem("systimes.idOperari" + api.getEmp())),
          fotos: imatges,
        };
      } else {
        dataFotos = {
          canal: ordre.canal,
          diari: ordre.diari,
          any: ordre.any,
          ordre: ordre.albara,
          operari: Number(localStorage.getItem("systimes.idOperari" + api.getEmp())),
          fotos: [],
        };
      }

      axios
        .post(api.URL() + "/api/v1/intercanviG", dataFitxada, api.header())
        .then((response) => {
          console.log(response);
          if (finalitzada == "N") {
            axios
              .post(api.URL() + "/api/v1/entrarMaterialG", dataMaterial, api.header())
              .then((response) => {
                console.log(response);
                localStorage.removeItem("systimes.material" + api.getEmp());
                localStorage.removeItem("systimes.dataIniciOrdre" + api.getEmp());
                localStorage.removeItem("systimes.horaIniciOrdre" + api.getEmp());
                localStorage.removeItem("systimes.ordre" + api.getEmp());
                // Enviar fotos
                if (imatges.length) {
                  axios
                    .post(api.URL() + "/api/v1/entrarFotosG", dataFotos, api.header())
                    .then(() => {
                      // Esborrar les fotos del store
                      this.$store.commit("setImatges", []);
                      this.$router.push("/");
                    })
                    .catch((error) => {
                      console.log(error);
                      this.$router.push("/");
                    });
                } else {
                  this.$router.push("/");
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            axios
              .post(api.URL() + "/api/v1/intercanviG", dataFinalitzacio, api.header())
              .then((response) => {
                console.log(response);
                axios
                  .post(api.URL() + "/api/v1/entrarMaterialG", dataMaterial, api.header())
                  .then((response) => {
                    console.log(response);
                    localStorage.removeItem("systimes.material" + api.getEmp());
                    localStorage.removeItem("systimes.dataIniciOrdre" + api.getEmp());
                    localStorage.removeItem("systimes.horaIniciOrdre" + api.getEmp());
                    localStorage.removeItem("systimes.ordre" + api.getEmp());
                    localStorage.removeItem("systimes.signatura" + api.getEmp());
                    if (imatges.length) {
                      // Enviar fotos
                      axios
                        .post(api.URL() + "/api/v1/entrarFotosG", dataFotos, api.header())
                        .then(() => {
                          // Esborrar les fotos del store
                          this.$store.commit("setImatges", []);
                          this.$router.push("/");
                        })
                        .catch((error) => {
                          console.log(error);
                          this.$router.push("/");
                        });
                    } else {
                      this.$router.push("/");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    this.$router.push("/");
                  });
              })
              .catch((error) => {
                console.log(error);
                this.$router.push("/");
              });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$router.push("/");
        });
    },
    eventSortida(lat, lng, ordre) {
      var estat;
      console.log("Entrada...");
      this.error = false;
      axios
        .post(api.URL() + "/api/v1/event", {
          id: Number(localStorage.getItem("systimes.idOperari" + api.getEmp())),
          tipus: "F",
          tipusFitxada: parseInt(
            localStorage.getItem("systimes.tipusFixada" + api.getEmp())
          ),
          lat: lat,
          lng: lng,
          ordre: ordre.albara.toString(),
          idPresencia: ordre.idPresencia,
        }, api.header())
        .then((response) => {
          var d = new Date();
          var time =
            d.getHours().toString().padStart(2, "0") +
            ":" +
            d.getMinutes().toString().padStart(2, "0");
          this.seconds = d.getSeconds().toString().padStart(2, "0");

          estat = this.$t('FinalOrdre') + response.data + " " + time;

          localStorage.setItem("systimes.estat" + api.getEmp(), estat);
          // this.$router.push("/");
        })
        .catch((err) => {
          console.log(err);
          this.error = true;
          this.tipusFitxada = 0;
          this.desactivarFi = false;
          if (err.response.status == 401) {
            if (localStorage.getItem("systimes.idioma" + api.getEmp()) == "Català") {
              this.estat = "Usuari no autoritzat";
            } else {
              this.estat = "Usuario no autorizado";
            }
          } else if (err.response.status == 409) {
            if (localStorage.getItem("systimes.idioma" + api.getEmp()) == "Català") {
              this.estat =
                "Presència ignorada per marge de temps amb una altra presència a les " +
                err.response.data;
            } else {
              this.estat =
                "Presencia ignorada por margen de tiempo con otra presencia a las" +
                err.response.data;
            }
          } else {
            if (localStorage.getItem("systimes.idioma" + api.getEmp()) == "Català") {
              this.estat = "Error connectant amb el servidor";
            } else {
              this.estat = "Error conectando con el servidor";
            }
          }

          // this.$router.push("/");
        });
    },

    deixarPendent(ordre, index) {
      this.finalitzarOrdre(ordre, index, "S", "P");
    },

    finalitzarOrdre(ordre, index, finalitzar, estat) {
      this.deshabilitarFinalitzar = true;

      var milis = new Date().getTime();
      console.log(milis, this.ultMilis);
      if (milis - this.ultMilis > 1000) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              this.eventSortida(
                position.coords.latitude,
                position.coords.longitude,
                ordre
              );
              console.log(position);
            },
            (error) => {
              console.log(error);
              this.eventSortida(0, 0, ordre);
            }
          );
        } else {
          this.eventSortida(0, 0, ordre);
        }
      }
      this.ultMilis = new Date().getTime();

      this.intercanvi(ordre, index, finalitzar, estat);
    },
  },
  created() {
    // axios
    //   .post(api.URL() + "/api/v1/ordresPendents", {
    //     id: Number(localStorage.getItem("systimes.idOperari" + api.getEmp())),
    //   }, api.header())
    //   .then((response) => {


    this.ordres = [];
    if (localStorage.getItem("systimes.ordre" + api.getEmp()) != null) {
      this.ordres.push(JSON.parse(localStorage.getItem("systimes.ordre" + api.getEmp())));
    }

    if (this.ordres.length == 0) {
      this.titol = this.$t("ResPerFinalitzar");
    } else {
      this.titol = this.$t("Finalitzar");
      this.principal = this.ordres[0].principal;
    }

    this.ordres.forEach((ordre, index) => {
      this.alertaMotiu[index] = false;
    });
    this.loadMaterial();
    // Carregar motius de no finalitzacio
    axios
      .get(api.URL() + "/api/v1/motiusNoFinalitzacioG", api.header())
      .then((response) => {
        this.motius = response.data;
      })
      .catch((err) => {
        console.log(err);
      }, api.header());
    // Carregar permis imputar
    axios.get(api.URL() + "/api/v1/permisImputar", api.header()).then((response) => {
      this.permisImputar = response.data;
    });
    // Carregar permis imputar material
    axios.get(api.URL() + "/api/v1/permisImputarMaterial", api.header()).then((response) => {
      this.permisImputarMaterial = response.data;
    });
    // })
    // .catch(() => {

    // this.titol = this.$t("ResPerFinalitzar");

    // });
  },
};
</script>
