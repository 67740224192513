<template>
  <v-container>
    <v-row>
      <div class="select">{{ nomOperari }}</div>
    </v-row>
    <v-row align="center">
      <v-chip v-if="error == false" class="mr-2">{{ estat }}</v-chip>
      <v-chip v-else color="red" class="mr-2">{{ estat }}</v-chip>
      <v-btn v-if="ordre && (principal || permisImputarMaterial || mode == 'N')" @click="entrarMaterial()" rounded
        color="blue" style="color: white">{{ $t("OpcionsOrdre") }}</v-btn>
    </v-row>

    <v-row justify="center">
      <span class="clock">{{ time }}</span>
    </v-row>

    <v-row xs12 v-if="mode != 'N'">
      <v-select class="select" v-model="tipusFitxada" :items="tipus" item-value="id" item-text="nom" return-object
        @change="onChangeTipus($event)"></v-select>
    </v-row>

    <v-row>
      <v-btn height="90px" block large @click="iniciar()" color="green">
        {{ $t("Iniciar") }}
        <v-icon right dark>mdi-arrow-right-thick</v-icon>
      </v-btn>
    </v-row>
    <v-row class="mt-6">
      <v-btn height="90px" block large @click="finalitzar()" color="red" :disabled="desactivarFi">
        {{ $t("Finalitzar") }}
        <v-icon right dark>mdi-arrow-left-thick</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import api from "../api.js";

export default {
  created() {
    axios.get(api.URL() + "/api/v1/mode", api.header()).then((response) => {
      this.mode = response.data;
      localStorage.setItem("systimes.mode" + api.getEmp(), this.mode);
    });
    axios
      .post(api.URL() + "/api/v1/token", { token: localStorage.getItem("systimes.token" + api.getEmp()) }, api.header())
      .then((response) => {
        if (response.data != "OK") this.$router.push("/Token");
      });
    this.loadData();
    this.tipusFitxada = 0;
    this.desactivarFi = false;
    if (localStorage.getItem("systimes.idioma" + api.getEmp()) == "Català") {
      this.$i18n.locale = "ca";
    } else {
      this.$i18n.locale = "es";
    }
  },
  mounted() {
    this.ordre = localStorage.getItem("systimes.ordre" + api.getEmp());
    if (this.ordre != null) {
      this.ordre = JSON.parse(this.ordre);
      this.principal = this.ordre?.principal == "S";
    }

    if (localStorage.getItem("systimes.inici" + api.getEmp()) == "1") {
      localStorage.setItem("systimes.inici" + api.getEmp(), "0");
      this.iniciar();
    }

    axios.get(api.URL() + "/api/v1/permisImputarMaterial", api.header()).then((response) => {
      this.permisImputarMaterial = (response.data == "S");
    });
  },
  data: () => ({
    error: false,
    version: localStorage.getItem("systimes.version"),
    nomOperari: localStorage.getItem("systimes.nomOperari" + api.getEmp()),
    tipusFitxada: 0,
    estat: localStorage.getItem("systimes.estat" + api.getEmp()),
    time: "--:--",
    seconds: "",
    tipus: [],
    ultMilis: new Date().getTime(),
    desactivarFi: false,
    mode: "",
    ordre: null,
    principal: false,
    permisImputarMaterial: false,
  }),
  methods: {
    entrarMaterial() {
      var or = localStorage.getItem("systimes.ordre" + api.getEmp());
      if (or != null && or != "") {
        this.$store.commit("setBack", "/");
        if (localStorage.getItem("systimes.mode" + api.getEmp()) == "N") {
          this.$router.push("Material");
        } else {
          this.$router.push("MaterialG");
        }
      }
    },
    eventEntrada(lat, lng, ordre) {
      console.log("Entrada...");
      this.error = false;
      axios
        .post(api.URL() + "/api/v1/event", {
          id: Number(localStorage.getItem("systimes.idOperari" + api.getEmp())),
          tipus: "I",
          tipusFitxada: this.tipusFitxada.id,
          lat: lat,
          lng: lng,
          ordre: ordre,
        }, api.header())
        .then(() => {
          if (this.tipusFitxada == 0) {
            this.estat = "Entrada " + this.time;
          } else {
            console.log(this.tipusFitxada);
            this.estat = this.tipusFitxada.nom + " " + this.time;
            this.tipusFitxada = 0;
            this.desactivarFi = false;
          }

          localStorage.setItem("systimes.estat" + api.getEmp(), this.estat);
        })
        .catch((err) => {
          console.log(err);
          this.error = true;
          this.tipusFitxada = 0;
          this.desactivarFi = false;
          if (err.response.status == 401) {
            if (localStorage.getItem("systimes.idioma" + api.getEmp()) == "Català") {
              this.estat = "Usuari no autoritzat";
            } else {
              this.estat = "Usuario no autorizado";
            }
          } else if (err.response.status == 409) {
            if (localStorage.getItem("systimes.idioma" + api.getEmp()) == "Català") {
              this.estat =
                "Presència ignorada per marge de temps amb una altra presència a les " +
                err.response.data;
            } else {
              this.estat =
                "Presencia ignorada por margen de tiempo con otra presencia a las" +
                err.response.data;
            }
          } else {
            if (localStorage.getItem("systimes.idioma" + api.getEmp()) == "Català") {
              this.estat = "Error connectant amb el servidor";
            } else {
              this.estat = "Error conectando con el servidor";
            }
          }
        });
    },
    eventSortida(lat, lng, ordre) {
      console.log("Sortida...");
      this.error = false;
      axios
        .post(api.URL() + "/api/v1/event", {
          id: Number(localStorage.getItem("systimes.idOperari" + api.getEmp())),
          tipus: "F",
          tipusFitxada: this.tipusFitxada.id,
          lat: lat,
          lng: lng,
          ordre: ordre,
        }, api.header())
        .then(() => {
          if (localStorage.getItem("systimes.idioma" + api.getEmp()) == "Català") {
            this.estat = "Sortida " + this.time;
          } else {
            this.estat = "Salida " + this.time;
          }

          localStorage.setItem("systimes.estat" + api.getEmp(), this.estat);
          this.tipusFitxada = 0;
          this.desactivarFi = false;
        })
        .catch((err) => {
          console.log(err);
          this.error = true;
          this.tipusFitxada = 0;
          this.desactivarFi = false;
          if (err.response.status == 401) {
            if (localStorage.getItem("systimes.idioma" + api.getEmp()) == "Català") {
              this.estat = "Usuari no autoritzat";
            } else {
              this.estat = "Usuario no autorizado";
            }
          } else if (err.response.status == 409) {
            if (localStorage.getItem("systimes.idioma" + api.getEmp()) == "Català") {
              this.estat =
                "Presència ignorada per marge de temps amb una altra presència a les " +
                err.response.data;
            } else {
              this.estat =
                "Presencia ignorada por margen de tiempo con otra presencia a las " +
                err.response.data;
            }
          } else {
            if (localStorage.getItem("systimes.idioma" + api.getEmp()) == "Català") {
              this.estat = "Error connectant amb el servidor";
            } else {
              this.estat = "Error conectando con el servidor";
            }
          }
        });
    },
    iniciar() {
      if (localStorage.getItem("systimes.mode" + api.getEmp()) == "C") {
        this.iniciar1();
      } else {
        axios
          .post(api.URL() + "/api/v1/operari", {
            id: Number(localStorage.getItem("systimes.idOperari" + api.getEmp())),
          }, api.header())
          .then((resp) => {
            if (resp.data.ordres == "S") {
              // TODO
              // axios
              //   .post(api.URL() + "/api/v1/hiHaOrdresPendents", {
              //     id: Number(localStorage.getItem("systimes.idOperari" + api.getEmp())),
              //   }, api.header())
              //   .then((response) => {
              let nOrdres = 0;
              if (localStorage.getItem("systimes.ordre" + api.getEmp()) != null) {
                nOrdres = 1;
              }

              if (localStorage.getItem("systimes.mode" + api.getEmp()) == "N") {
                if (nOrdres > 0) {
                  localStorage.setItem("systimes.inici" + api.getEmp(), "1");
                  this.$router.push("/OrdresPendents");
                } else {
                  this.$router.push("/Ordres");
                }
              } else {
                if (nOrdres > 0) {
                  localStorage.setItem("systimes.inici" + api.getEmp(), "1");
                  this.$router.push("/OrdresPendentsG");
                } else {
                  localStorage.setItem(
                    "systimes.tipusFixada" + api.getEmp(),
                    this.tipusFitxada.toString()
                  );
                  this.$router.push("/OrdresG");
                }
              }
              // });
            } else {
              this.iniciar1();
            }
          })
          .catch(() => {
            this.iniciar1();
          });
      }
    },
    iniciar1() {
      var milis = new Date().getTime();
      console.log(milis, this.ultMilis);
      if (milis - this.ultMilis > 1000) {
        /*
        this.$getLocation()
          .then((coordinates) => {
            this.eventEntrada(coordinates.lat, coordinates.lng);
            console.log(coordinates);
          })
          .catch(() => {
            this.eventEntrada(0, 0, "");
          });
        */
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              this.eventEntrada(
                position.coords.latitude,
                position.coords.longitude
              );
              console.log(position);
            },
            (error) => {
              console.log(error);
              this.eventEntrada(0, 0, "");
            }
          );
        } else {
          this.eventEntrada(0, 0, "");
        }
      }
      this.ultMilis = new Date().getTime();
    },

    finalitzar() {
      if (localStorage.getItem("systimes.mode" + api.getEmp()) == "C") {
        this.finalitzar1();
      } else {
        axios
          .post(api.URL() + "/api/v1/operari", {
            id: Number(localStorage.getItem("systimes.idOperari" + api.getEmp())),
          }, api.header())
          .then((resp) => {
            if (resp.data.ordres == "S") {
              /*
              this.$getLocation();
                .then((coordinates) => {
                  this.finalitzar0(coordinates.lat, coordinates.lng);
                })
                .catch(() => {
                  this.finalitzar0(0.0, 0.0);
                });
              */
              if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                  (position) => {
                    this.finalitzar0(
                      position.coords.latitude,
                      position.coords.longitude
                    );
                    console.log(position);
                  },
                  (error) => {
                    console.log(error);
                    this.finalitzar0(0.0, 0.0);
                  }
                );
              } else {
                this.finalitzar0(0.0, 0.0);
              }
            } else {
              this.finalitzar1();
            }
          })
          .catch((error) => {
            console.log(error);
            this.finalitzar1();
          });
      }
    },
    finalitzar0(lat, lng) {
      localStorage.setItem("systimes.lat" + api.getEmp(), lat);
      localStorage.setItem("systimes.lng" + api.getEmp(), lng);
      if (localStorage.getItem("systimes.mode" + api.getEmp()) == "N") {
        this.$router.push("/OrdresPendents");
      } else {
        this.$router.push("/OrdresPendentsG");
      }
      /*
      axios
        .post(api.URL() + "/api/v1/sortidaOrdre", {
          idOperari: Number(localStorage.getItem("systimes.idOperari" + api.getEmp())),
          nomOperari: localStorage.getItem("systimes.nomOperari" + api.getEmp()),
          lat: lat,
          lng: lng,
        }, api.header())
        .then(() => {
          this.estat = "Sortida " + this.time;
          localStorage.setItem("systimes.estat" + api.getEmp(), this.estat);
        })
        .catch(() => {});
        */
    },
    finalitzar1() {
      var milis = new Date().getTime();
      if (milis - this.ultMilis > 1000) {
        /*
        this.$getLocation()
          .then((coordinates) => {
            this.eventSortida(coordinates.lat, coordinates.lng);
            console.log(coordinates);
          })
          .catch(() => {
            this.eventSortida(0, 0, "");
          });
        */
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              this.eventSortida(
                position.coords.latitude,
                position.coords.longitude,
                ""
              );
              console.log(position);
            },
            (error) => {
              console.log(error);
              this.eventSortida(0, 0, "");
            }
          );
        } else {
          this.eventSortida(0, 0, "");
        }
      }
      this.ultMilis = new Date().getTime();
    },
    getTime() {
      axios.get(api.URL() + "/api/v1/time", api.header()).then((response) => {
        this.time = response.data;
        localStorage.setItem("systimes.time" + api.getEmp(), this.time);
      });
    },
    clock() {
      this.estat = localStorage.getItem("systimes.estat" + api.getEmp());

      var d = new Date();
      this.time =
        d.getHours().toString().padStart(2, "0") +
        ":" +
        d.getMinutes().toString().padStart(2, "0");
      this.seconds = d.getSeconds().toString().padStart(2, "0");
      localStorage.setItem("systimes.time" + api.getEmp(), this.time);
    },
    loadData() {
      axios.get(api.URL() + "/api/v1/tipus", api.header()).then((response) => {
        this.tipus = response.data;
      });
    },
    onChangeTipus(event) {
      this.desactivarFi = !(event.id == 0);
    },
  },

  timers: {
    clock: {
      time: 1000,
      repeat: true,
      inmediate: true,
      autostart: true,
    },
    loadData: {
      time: 60000,
      repeat: true,
      inmediate: true,
      autostart: true,
    },
  },
};
</script>

<style>
.clock {
  font-size: 100px;
  font-weight: bold;
}

.select {
  font-size: 20px;
  font-weight: bold;
}

.version {
  font-size: 10px;
  font-weight: bold;
}
</style>


