var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-row',[_c('v-btn',{staticClass:"mb-2",attrs:{"fab":"","to":"/"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-arrow-left-thick")])],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',{staticClass:"blue"},[_c('v-container',[_c('v-row',[_c('v-expansion-panels',{staticClass:"mt-n2",attrs:{"tile":"","flat":""}},[_c('v-expansion-panel',{staticStyle:{"background":"#2196f3"}},[_c('v-expansion-panel-header',[_c('div',{staticClass:"text-h6 ml-n6"},[_vm._v(" "+_vm._s(_vm.$t("Ordre"))+" "+_vm._s(_vm.ordre.albara)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.ordre.nom)+" ("+_vm._s(_vm.ordre.client)+") ")])]),_c('v-expansion-panel-content',[_c('div',{staticClass:"text-subtitle-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("Adreca")))]),_vm._v(" "+_vm._s(_vm.ordre.domicili)+" ")]),_c('div',{staticClass:"text-subtitle-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("Poblacio")))]),_vm._v(" "+_vm._s(_vm.ordre.poblacio)+" ")]),_c('div',{staticClass:"text-subtitle-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("Telefon")))]),_vm._v(" "+_vm._s(_vm.ordre.telefon)+" ")])])],1)],1)],1)],1)],1),_c('v-card-text',[_c('h3',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t("Feines_a_fer")))]),_c('v-form',[(
                  _vm.ordre.observacionsOrdre != null &&
                  _vm.ordre.observacionsOrdre.trim() != ''
                )?_c('v-textarea',{attrs:{"auto-grow":"","rows":"1","readonly":"","value":_vm.ordre.observacionsOrdre,"hide-details":"auto"}}):_vm._e(),(
                  _vm.ordre.descripcio != null && _vm.ordre.descripcio.trim() != ''
                )?_c('v-text-field',{attrs:{"readonly":"","value":_vm.ordre.descripcio,"hide-details":"auto"}}):_vm._e(),(
                  _vm.ordre.observacionsSubordre != null &&
                  _vm.ordre.observacionsSubordre.trim() != ''
                )?_c('v-textarea',{attrs:{"auto-grow":"","rows":"1","readonly":"","value":_vm.ordre.observacionsSubordre,"hide-details":"auto"}}):_vm._e()],1)],1)],1)],1)],1)],1),_c('v-container',[_c('v-row',{staticClass:"mt-5 mb-5",attrs:{"justify":"center"}},[_c('v-btn',{on:{"click":function($event){return _vm.firmar()}}},[_vm._v(_vm._s(_vm.$t("FirmarOrdre")))])],1),_c('v-row',[_c('h2',{staticClass:"ml-3 mt-4 mb-2"},[_vm._v(_vm._s(_vm.$t("Petit_material")))])]),_vm._l((_vm.articles),function(item,index){return _c('v-row',{key:index,staticClass:"mb-1"},[_c('v-col',[_c('v-card',[_c('v-card-title',{staticClass:"grey lighten-3"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-checkbox',{model:{value:(_vm.checkbox[index]),callback:function ($$v) {_vm.$set(_vm.checkbox, index, $$v)},expression:"checkbox[index]"}}),_c('span',{staticClass:"text-body-1 font-weight-bold",on:{"click":function($event){return _vm.select(index)}}},[_vm._v(_vm._s(item.descripcio))])],1)],1)],1),(_vm.checkbox[index])?_c('v-card-text',[_c('v-form',{ref:'form_' + index,refInFor:true},[_c('v-text-field',{attrs:{"label":_vm.$t('Quantitat'),"type":"number","rules":[(v) => !!v || _vm.$t('Requerit')]},model:{value:(_vm.quantitat[index]),callback:function ($$v) {_vm.$set(_vm.quantitat, index, $$v)},expression:"quantitat[index]"}})],1)],1):_vm._e()],1)],1)],1)}),_c('v-row',{staticClass:"ml-0"},[_c('v-btn',{attrs:{"to":"/"}},[_vm._v(" "+_vm._s(_vm.$t("Cancelar"))+" ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"green"},on:{"click":function($event){return _vm.send()}}},[_vm._v(" "+_vm._s(_vm.$t("Acceptar"))+" ")])],1),_c('v-row',[_c('v-btn',{staticClass:"mt-6",attrs:{"fab":"","to":"/"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-arrow-left-thick")])],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }