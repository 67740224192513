var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('h1',[_vm._v(_vm._s(_vm.$t("Ordres")))])]),_vm._l((_vm.ordres),function(item,index){return _c('v-row',{key:index,staticClass:"mb-1"},[_c('v-col',[_c('v-card',[_c('v-card-title',{staticClass:"blue"},[_c('v-container',[_c('v-row',[_c('v-expansion-panels',{staticClass:"mt-n2",attrs:{"tile":"","flat":""}},[_c('v-expansion-panel',{staticStyle:{"background":"#2196f3"}},[_c('v-expansion-panel-header',[_c('div',{staticClass:"text-h6 ml-n6"},[_vm._v(" "+_vm._s(_vm.$t("Ordre"))+" "+_vm._s(item.albara)+" "),_c('br'),_vm._v(" "+_vm._s(item.nom)+" ("+_vm._s(item.client)+") ")])]),_c('v-expansion-panel-content',[_c('div',{staticClass:"text-subtitle-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("Adreca")))]),_vm._v(" "+_vm._s(item.domicili)+" ")]),_c('div',{staticClass:"text-subtitle-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("Poblacio")))]),_vm._v(" "+_vm._s(item.poblacio)+" ")]),_c('div',{staticClass:"text-subtitle-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("Telefon")))]),_vm._v(" "+_vm._s(item.telefon)+" ")])])],1)],1)],1)],1)],1),_c('v-card-text',[_c('h3',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t("Feines_a_fer")))]),_c('v-form',[(
                item.observacionsOrdre != null &&
                item.observacionsOrdre.trim() != ''
              )?_c('v-textarea',{attrs:{"auto-grow":"","rows":"1","readonly":"","value":item.observacionsOrdre,"hide-details":"auto"}}):_vm._e(),_c('v-expansion-panels',{attrs:{"tile":"","flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticStyle:{"padding":"0"}},[(
                      item.descripcio != null && item.descripcio.trim() != ''
                    )?_c('h4',[_vm._v(" "+_vm._s(item.descripcio)+" ")]):_vm._e()]),_c('v-expansion-panel-content',{staticStyle:{"padding":"0 0 0"}},[(
                      item.observacionsSubordre != null &&
                      item.observacionsSubordre.trim() != ''
                    )?_c('v-textarea',{attrs:{"auto-grow":"","rows":"1","readonly":"","value":item.observacionsSubordre,"hide-details":"auto"}}):_vm._e()],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"mt-2",attrs:{"color":"green"},on:{"click":function($event){return _vm.iniciarOrdre(item)}}},[_vm._v(" "+_vm._s(_vm.$t("Iniciar"))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-arrow-right-thick")])],1)],1)],1)],1)],1)}),_c('v-row',[_c('v-btn',{staticClass:"mt-4",attrs:{"fab":"","to":"/"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-arrow-left-thick")])],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }