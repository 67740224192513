import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import Cookies from "js-cookie";
import api from './api.js';
import VueTimers from 'vue-timers';
import VueGeolocation from 'vue-browser-geolocation';
import VueSignaturePad from 'vue-signature-pad';
import './registerServiceWorker'

Vue.use(VueTimers);
Vue.use(VueGeolocation);
Vue.use(VueSignaturePad);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
  methods: {
    update() {
      console.log("Comprovant actualitzacions...");
      fetch(api.URL() + "/api/v1/version")
        .then((response) => {
          return response.json();
        })
        .then((version) => {
          console.log(version);
          if (Cookies.get("version") != version) {
            console.log("Actualitzo!");
            Cookies.set("version", version, {
              expires: 8000,
            });
            window.location.reload(true);
          }
        });
    },
  },
  timers: {
    update: {
      time: 30000,
      repeat: true,
      inmediate: true,
      autostart: true,
    },
  },
}).$mount("#app");
