<template>
  <div>
    <v-flex xs12>
      <v-text-field v-model="clau" :label="$t('Clau')"></v-text-field>
    </v-flex>
    <div class="text-xs-right">
      <v-btn color="success" @click="guardar">{{ $t("Acceptar") }}</v-btn>
    </div>
    <v-alert :value="error" type="error">{{ $t("Clau_incorrecta") }}</v-alert>
  </div>
</template>

<script>
import axios from "axios";
import api from "../api.js";

export default {
  data: () => ({
    clau: "",
    error: false,
  }),
  created() {
    axios
      .post(api.URL() + "/api/v1/token", { token: localStorage.getItem("systimes.token" + api.getEmp()) })
      .then((response) => {
        if (response.data == "OK") {
          this.$store.commit("setAuth", true);
          this.$router.push("/");
        } else {
          this.$store.commit("setAuth", false);
        }
      }, api.header());
  },
  methods: {
    guardar() {
      if (this.clau != "") {
        localStorage.setItem("systimes.token" + api.getEmp(), this.clau);
        axios
          .post(api.URL() + "/api/v1/token", { token: this.clau })
          .then((response) => {
            if (response.data == "OK") {
              this.$store.commit("setAuth", true);
              this.$router.push("/");
            } else {
              this.$store.commit("setAuth", false);
            }

          }, api.header());
      }
    },
  },
};
</script>
