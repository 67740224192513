import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    auth: false,
    back: "",
    imatges: [],
  },
  mutations: {
    setAuth(state, auth) {
      state.auth = auth;
    },
    setBack(state, back) {
      state.back = back;
    },
    setImatges(state, imatges) {
      state.imatges = imatges;
    }
  },
  actions: {
  },
  modules: {
  }
})
