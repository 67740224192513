<template>
  <v-container>
    <v-row>
      <h1>{{ titol }}</h1>
    </v-row>
    <v-row v-for="(item, index) in ordres" :key="index" class="mb-1">
      <v-col>
        <v-card>
          <v-card-title class="red">
            <v-container>
              <v-row>
                <v-expansion-panels tile flat class="mt-n2">
                  <v-expansion-panel style="background: #f44636">
                    <v-expansion-panel-header>
                      <div class="text-h6 ml-n6">
                        {{ $t("Ordre") }} {{ item.ordre }} /
                        {{ item.subordre }}
                        <span v-if="item.mostrarNoms == 'C' || item.mostrarNoms == 'T'
                          ">
                          <br />
                          {{ item.nomClient }}
                        </span>
                        <span v-if="item.mostrarNoms == 'V' || item.mostrarNoms == 'T'
                          ">
                          <br />
                          {{ item.nomVaixell }}
                        </span>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="text-subtitle-1">
                        <span class="font-weight-bold">{{
                          $t("Data entrega:")
                        }}</span>
                        {{ item.dataEntrega }}
                      </div>
                      <div class="text-subtitle-1">
                        <span class="font-weight-bold"> Matrícula: </span>
                        {{ item.matricula }}
                      </div>
                      <div class="text-subtitle-1">
                        <span class="font-weight-bold"> Eslora: </span>
                        {{ item.eslora }}
                      </div>
                      <div class="text-subtitle-1">
                        <span class="font-weight-bold">{{
                          $t("Situació:")
                        }}</span>
                        {{ item.situacio }}
                      </div>
                      <div class="text-subtitle-1">
                        <span class="font-weight-bold">{{ $t("Claus:") }}</span>
                        {{ item.claus }}
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </v-container>
          </v-card-title>
          <v-card-text>
            <h3 class="mt-2">{{ $t("Feines_a_fer") }}</h3>
            <v-form>
              <v-textarea v-if="item.observacionsOrdre != null &&
                item.observacionsOrdre.trim() != ''
                " auto-grow rows="1" readonly :value="item.observacionsOrdre" hide-details="auto"></v-textarea>
              <v-text-field v-if="item.descripcio != null && item.descripcio.trim() != ''" readonly
                :value="item.descripcio" hide-details="auto"></v-text-field>
              <v-textarea v-if="item.observacionsSubordre != null &&
                item.observacionsSubordre.trim() != ''
                " auto-grow rows="1" readonly :value="item.observacionsSubordre" hide-details="auto"></v-textarea>
            </v-form>

            <h3 class="mt-6">{{ $t("FeinesFetes") }}</h3>
            <v-form>
              <v-textarea v-model="feinesFetes[index]" hide-details="auto"></v-textarea>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-container>
              <v-row>
                <v-col>
                  <v-btn :disabled="pendentVisible" @click="finalitzarOrdre(item, index)" color="green" class="mt-2 ml-2">
                    {{ $t("Finalitzar") }}
                  </v-btn>
                  <v-btn @click="pendentVisible = !pendentVisible" color="blue" class="mt-2 ml-2">
                    {{ $t("DeixarPendent") }}
                    <v-icon v-if="pendentVisible" right dark>mdi-chevron-up</v-icon>
                    <v-icon v-else right dark>mdi-chevron-down</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-card flat v-if="pendentVisible">
                    <v-card-text>
                      <v-radio-group :label="$t('Motiu')" v-model="motiu[index]" @change="alertaMotiu[index] = false">
                        <v-radio :label="$t('Falta_material')"></v-radio>
                        <v-radio :label="$t('Falta_temps')"></v-radio>
                        <v-radio :label="$t('Continuar_tarda')"></v-radio>
                        <v-radio :label="$t('Altres')"></v-radio>
                      </v-radio-group>
                      <v-textarea v-model="observacionsPendent[index]" label="Observacions"
                        hide-details="auto"></v-textarea>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn color="blue" class="mr-2" @click="deixarPendent(item, index)">
                        {{ $t("DeixarPendent") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                  <v-alert v-model="alertaMotiu[index]" color="red">
                    {{ $t("Cal_triar_un_motiu") }}</v-alert>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-btn class="mt-4" fab @click="enrera()">
        <v-icon dark>mdi-arrow-left-thick</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import api from "../api.js";

export default {
  data: () => ({
    pendentVisible: false,
    ordres: [],
    feinesFetes: [],
    ultMilis: new Date().getTime(),
    alertaMotiu: [],
    motiu: [],
    observacionsPendent: [],
    titol: "",
  }),
  methods: {
    enrera() {
      localStorage.setItem("systimes.inici" + api.getEmp(), "0");
      this.$router.push("/");
    },
    /*
    iniciarOrdre1(ordre, lat, lng) {
      axios
        .post(api.URL() + "/api/v1/entradaOrdre", {
          ordre: ordre.ordre,
          subordre: ordre.subordre,
          idOperari: Number(localStorage.getItem("systimes.idOperari" + api.getEmp())),
          nomOperari: localStorage.getItem("systimes.nomOperari" + api.getEmp()),
          lat: lat,
          lng: lng,
        }, api.header())
        .then(() => {
          var time = localStorage.getItem("systimes.time" + api.getEmp());
          var estat;

          if (ordre.nomVaixell != "") {
            estat = "Inici ordre " + ordre.nomVaixell + " " + time;
          } else {
            estat =
              "Inici ordre " + ordre.ordre + "/" + ordre.subordre + " " + time;
          }
          localStorage.setItem("systimes.estat" + api.getEmp(), estat);
          this.$router.push("/");
        })
        .catch(() => {});
    },
    */

    deixarPendent(ordre, index) {
      if (this.motiu[index] !== undefined) {
        this.alertaMotiu[index] = false;

        var milis = new Date().getTime();
        console.log(milis, this.ultMilis);
        if (milis - this.ultMilis > 1000) {
          /*
          this.$getLocation()
            .then((coordinates) => {
              this.finalitzarOrdre1(
                ordre,
                index,
                coordinates.lat,
                coordinates.lng,
                true
              );
            })
            .catch(() => {
              this.finalitzarOrdre1(ordre, index, 0.0, 0.0, true);
            });
          */
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                this.finalitzarOrdre1(
                  ordre,
                  index,
                  position.coords.latitude,
                  position.coords.longitude,
                  true
                );
                console.log(position);
              },
              (error) => {
                console.log(error);
                this.finalitzarOrdre1(ordre, index, 0.0, 0.0, true);
              }
            );
          } else {
            this.finalitzarOrdre1(ordre, index, 0.0, 0.0, true);
          }
        }
        this.ultMilis = new Date().getTime();
      } else {
        this.alertaMotiu[index] = true;
        this.$forceUpdate();
      }
    },

    finalitzarOrdre1(ordre, index, lat, lng, pendent) {
      console.log(this.motiu[index]);

      var motiu = "";
      if (this.motiu[index] !== undefined) {
        switch (this.motiu[index]) {
          case 0:
            motiu = "M";
            break;
          case 1:
            motiu = "T";
            break;
          case 2:
            motiu = "C";
            break;
          case 3:
            motiu = "A";
        }
      }

      axios
        .post(api.URL() + "/api/v1/finalitzarOrdre", {
          ordre: ordre.ordre,
          subordre: ordre.subordre,
          idOperari: Number(localStorage.getItem("systimes.idOperari" + api.getEmp())),
          nomOperari: localStorage.getItem("systimes.nomOperari" + api.getEmp()),
          lat: lat,
          lng: lng,
          detall: ordre.detall,
          feinesFetes: this.feinesFetes[index],
          motiu: motiu,
          observacionsPendent: this.observacionsPendent[index],
          pendent: pendent,
        }, api.header())
        .then(() => {
          var time = localStorage.getItem("systimes.time" + api.getEmp());
          var estat;
          var nom;

          if (ordre.mostrarNoms == "V") {
            nom = ordre.nomVaixell;
          } else {
            nom = ordre.nomClient;
          }

          if (nom != "") {
            estat = this.$t('FinalOrdre') + nom + " " + time;
          } else {
            estat =
              this.$t('FinalOrdre') + ordre.ordre + "/" + ordre.subordre + " " + time;
          }
          localStorage.setItem("systimes.estat" + api.getEmp(), estat);
          //localStorage.setItem("systimes.ordre" + api.getEmp(), JSON.stringify(ordre));
          localStorage.removeItem("systimes.ordre" + api.getEmp());
          this.$router.push("/");
        })
        .catch(() => {
          console.log("MALAMENT!");
        });
    },
    finalitzarOrdre(ordre, index) {
      var milis = new Date().getTime();
      console.log(milis, this.ultMilis);
      if (milis - this.ultMilis > 1000) {
        /*
        this.$getLocation()
          .then((coordinates) => {
            this.finalitzarOrdre1(
              ordre,
              index,
              coordinates.lat,
              coordinates.lng,
              false
            );
          })
          .catch(() => {
            this.finalitzarOrdre1(ordre, index, 0.0, 0.0, false);
          });
        */
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              this.finalitzarOrdre1(
                ordre,
                index,
                position.coords.latitude,
                position.coords.longitude,
                false
              );
              console.log(position);
            },
            (error) => {
              console.log(error);
              this.finalitzarOrdre1(ordre, index, 0.0, 0.0, false);
            }
          );
        } else {
          this.finalitzarOrdre1(ordre, index, 0.0, 0.0, false);
        }
      }
      this.ultMilis = new Date().getTime();
    },
  },
  created() {
    axios
      .post(api.URL() + "/api/v1/ordresPendents", {
        id: Number(localStorage.getItem("systimes.idOperari" + api.getEmp())),
      }, api.header())
      .then((response) => {
        this.ordres = response.data;

        if (this.ordres.length == 0) this.titol = this.$t("ResPerFinalitzar");
        else this.titol = this.$t("Finalitzar");

        this.ordres.forEach((ordre, index) => {
          this.alertaMotiu[index] = false;
        });
      })
      .catch(() => {
        this.titol = this.$t("ResPerFinalitzar");
      });
  },
};
</script>
