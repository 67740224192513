<template>
  <div>
    <v-row class="mb-5">
      <v-col center>
        <v-btn fab @click="back" class="mr-6">
          <v-icon dark>mdi-arrow-left-thick</v-icon>
        </v-btn>

        <v-btn @click="takePhoto()">
          <v-icon class="mr-3">mdi-camera</v-icon> {{ $t("FerFoto") }}</v-btn
        >
      </v-col>
    </v-row>

    <v-container>
      <v-row v-for="(item, index) in imatges" :key="index" class="mb-2">
        <v-card>
          <v-card-text>
            <!-- boto per esborrar la foto -->
            <v-btn
              class="ml-2 mb-4"
              color="red"
              @click="deletePhoto(index)"
              fab
              small
              dark
              bottom
              right
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-img :src="item"></v-img>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>

    <input
      type="file"
      accept="image/jpeg"
      capture="camera"
      id="camera"
      style="visibility: hidden"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      nImatges: 0,
      imatges: [],
    };
  },
  mounted() {
    const camera = document.getElementById("camera");
    camera.addEventListener("change", (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imatges.unshift(e.target.result);
        ++this.nImatges;

        // force update
        this.$forceUpdate();

        // Save the images in the store
        this.$store.commit("setImatges", this.imatges);
      };
      reader.readAsDataURL(file);
    });
  },
  methods: {
    takePhoto() {
      const camera = document.getElementById("camera");
      camera.click();
    },
    deletePhoto(index) {
      this.imatges.splice(index, 1);
      --this.nImatges;
      // force update
      this.$forceUpdate();
    },
    back() {
      this.$router.go(-1);
    },
  },
  created() {
    // Get the images from the store
    this.imatges = this.$store.state.imatges;
    this.nImatges = this.imatges.length;
  },
};
</script>