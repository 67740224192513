<template>
    <div id="app">
      <VueSignaturePad width="100%" height="500px" ref="signaturePad" />
      <v-row>
        <v-btn @click="save">{{ $t("Guardar") }}</v-btn>
        <v-btn class="ml-4" @click="undo">{{ $t("Desfer") }}</v-btn>
      </v-row>
    </div>
  </template>
  <script>
import api from '../api';

  export default {
    name: 'MySignaturePad',
    methods: {
      undo() {
        this.$refs.signaturePad.undoSignature();
      },
      save() {
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature("image/png");
        console.log(isEmpty);
        console.log(data);
        localStorage.setItem('systimes.signatura' + api.getEmp(), data);
        this.$router.go(-1);
      },
      
    },
    mounted() {
      const signatura = localStorage.getItem('systimes.signatura' + api.getEmp());
      if (signatura) {
        this.$refs.signaturePad.fromDataURL(signatura);
      }
    }
    
  };
  </script>